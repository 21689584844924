'use client';

import { useCurrencyContext } from '@contexts/currency-context';
import { useBetterMediaQuery } from '@hooks/use-ssr-media-query';
import { USER_ROLE } from '@utils/auth/roles';
import { formatCurrencyV2 } from '@utils/format-currency-v2';
import { v2Links } from '@utils/navigation/links';
import { AgencyPerformance } from '@v2/types';
import { Button } from '@v2/ui';
import { format } from 'date-fns';
import { useSession } from 'next-auth/react';
import Link from 'next/link';

function ItemOverviewSubItem({
  title,
  value,
}: {
  title: string;
  value: string | number;
}) {
  return (
    <div className="flex items-center justify-between">
      <span className="md:text-md text-sm font-medium text-gray-500">
        {title}
      </span>
      <span className="md:text-md text-sm font-bold"> {value} </span>
    </div>
  );
}

type ItemOverViewProps = {
  label: String;
  mainlabel: number;
  children: React.ReactNode;
  path: string | null;
};
export function ItemOverView(props: ItemOverViewProps) {
  const md = useBetterMediaQuery('(min-width: 768px)');

  const { label, children, mainlabel, path } = props;
  return (
    <div className="flex w-full flex-col gap-y-4 rounded-xl border border-gray-200 p-4 md:gap-y-6 md:p-6">
      <div className="flex flex-col gap-y-1.5">
        <div className="flex items-center justify-between">
          <h5 className="text- md font-bold text-gray-800 md:text-lg">
            {' '}
            {label}{' '}
          </h5>

          {path ? (
            <Link href={path} prefetch={false}>
              <Button
                variant="tertiary"
                className="p-0"
                size={md ? 'lg' : 'md'}
              >
                View{' '}
              </Button>
            </Link>
          ) : (
            <div />
          )}
        </div>
        <h3 className="text-4xl font-bold tracking-[-0.72px]">{mainlabel} </h3>
      </div>
      {children}
    </div>
  );
}

export function OrdersOverview(
  props: AgencyPerformance & { agencyId: number }
) {
  const { priceRate, currency } = useCurrencyContext();

  console.log(props);
  const {
    totalReferrals,
    usersNr,
    totalSales,
    totalNrOfVisits,
    totalRevenue,
    totalValue,
    amountSaved,
    lastUsed,
    lastCreated,
    agencyId,
    codeCount,
  } = props;

  const { data } = useSession();
  // @ts-ignore
  const isAMember = data?.user?.role === USER_ROLE.agencyMember;

  // @ts-ignore
  const isSuperAdmin = data?.user?.role === USER_ROLE.superAdmin;
  return (
    <div className="grid w-full gap-3 md:gap-4 lg:grid-cols-3">
      {/* REFERRALS */}
      <ItemOverView
        label="Referrals"
        mainlabel={totalReferrals}
        path={
          isSuperAdmin
            ? `/dashboard/admin/agencies/view/performance?agencyId=${agencyId}`
            : isAMember
              ? null
              : v2Links.partnersPerformance
        }
      >
        <div className="flex flex-col space-y-2">
          <ItemOverviewSubItem title="Referred users" value={usersNr || '-'} />
          <ItemOverviewSubItem title="Total sales" value={totalSales || '-'} />
          <ItemOverviewSubItem
            title="Total link visits"
            value={totalNrOfVisits || '-'}
          />
        </div>
      </ItemOverView>

      {/* ORDERS */}
      <ItemOverView
        label="Orders"
        mainlabel={totalSales}
        path={
          isSuperAdmin
            ? `/dashboard/admin/agencies/view/sales?agencyId=${agencyId}`
            : v2Links.partnerSales
        }
      >
        <div className="flex flex-col space-y-2">
          <ItemOverviewSubItem title="Total orders" value={totalSales || '-'} />
          <ItemOverviewSubItem
            title="Total value"
            value={
              totalValue
                ? `${formatCurrencyV2(
                    totalValue,
                    priceRate,
                    currency,
                    false,
                    false
                  )}`
                : '-'
            }
          />
          <ItemOverviewSubItem
            title="Total revenue"
            value={
              totalRevenue
                ? `${formatCurrencyV2(
                    totalRevenue,
                    priceRate,
                    currency,
                    false,
                    false
                  )}`
                : '-'
            }
          />
        </div>
      </ItemOverView>

      {/* PROMO CODES */}
      <ItemOverView
        label="Promo codes"
        mainlabel={codeCount}
        path={
          isSuperAdmin
            ? `/dashboard/admin/agencies/view/promoCodes?agencyId=${agencyId}`
            : isAMember
              ? null
              : v2Links.partnerPromoCodes
        }
      >
        <div className="flex flex-col space-y-2">
          <ItemOverviewSubItem
            title="Amount saved"
            value={
              amountSaved
                ? `${formatCurrencyV2(
                    amountSaved,
                    priceRate,
                    currency,
                    false,
                    false
                  )}`
                : '-'
            }
          />
          <ItemOverviewSubItem
            title="Last used"
            value={lastUsed ? format(new Date(lastUsed), 'MMM dd, yyyy') : '-'}
          />
          <ItemOverviewSubItem
            title="Last created"
            value={lastCreated ? format(lastCreated, 'MMM dd, yyyy') : '-'}
          />
        </div>
      </ItemOverView>
    </div>
  );
}
