'use client';

import {
  AlertToast,
  AlertTriangle,
  Button,
  Input,
  Label,
  Typography,
} from '@v2/ui';
import { useFormik } from 'formik';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import * as yup from 'yup';

import { useAuthUserType } from '@hooks/use-auth-user-type';
import { v2Links } from '@utils/navigation/links';
import { passwordRegex, passwordResetWithCode } from '@v2/action';
import toast from 'react-hot-toast';
import { PasswordStrengthVisual } from '../components/PasswordStrengthVisual';

import { cn } from '@v2/utils';

export const signupValidationSchema = yup.object().shape({
  password: yup.string().min(8).matches(passwordRegex).required('Required'),
  repeatPassword: yup
    .string()
    .min(6)
    .required('Required')
    .oneOf([yup.ref('password'), ''], 'Passwords do not match.')
    .label('repeat password'),
});

interface SignUpProps {
  password: string;
  repeatPassword: string;
}

type Props = {
  code: string;
  email: string;
};
export function ChooseANewPassword(props: Props) {
  const [loading, setLoading] = useState(false);
  const userType = useAuthUserType();

  const { email, code } = props;
  const router = useRouter();

  const { values, handleChange, handleBlur, handleSubmit, errors, touched } =
    useFormik<SignUpProps>({
      onSubmit: async (val) => {
        try {
          setLoading(true);

          const res = await passwordResetWithCode({
            code,
            email,
            password: val.password,
            userType,
          });

          if (res === 'Invalid code.')
            return toast.custom(
              (t) => (
                <AlertToast
                  {...t}
                  title="Oops something went wrong"
                  content={res}
                  icon={<AlertTriangle />}
                />
              ),
              { position: 'top-right' }
            );

          router.push(`${v2Links.login}?userType=${userType}`);
        } catch (e: any) {
          console.log(e);
        } finally {
          setLoading(false);
        }
      },
      initialValues: {
        password: '',
        repeatPassword: '',
      },
      validationSchema: signupValidationSchema,
    });

  return (
    <>
      <div>
        <Typography
          variant="h1"
          size={'dsm'}
          className={cn(
            'mb-3 text-center',
            userType === 'agency' && 'text-white'
          )}
        >
          Choose a new password
        </Typography>
        <Typography
          variant="p"
          size={'tmd'}
          className={cn(
            'text-center font-medium',
            userType === 'agency' ? 'text-gray-400' : 'text-gray-500'
          )}
        >
          You’re resetting the credentials for{' '}
          <span className="font-bold">{email}</span>
        </Typography>
      </div>
      <form
        action="post"
        onSubmit={handleSubmit}
        className="flex w-full flex-col gap-y-8"
      >
        <div className="flex w-full flex-col gap-y-5">
          <div className="f grid w-full items-center gap-1.5">
            <Label
              htmlFor="password"
              className={cn(
                userType === 'agency' && 'text-gray-400 md:text-gray-400'
              )}
            >
              Password
            </Label>
            <Input
              name="password"
              type="password"
              id="password"
              value={values.password}
              onChange={handleChange}
              required
              isError={errors.password !== undefined && touched.password}
            />
          </div>
          {/* PASSWORD PROGRESS VISUAL */}
          <PasswordStrengthVisual password={values.password} />

          <div className="f grid w-full items-center gap-1.5">
            <Label
              htmlFor="repeatPasword"
              className={cn(
                userType === 'agency' && 'text-gray-400 md:text-gray-400'
              )}
            >
              Confirm password
            </Label>
            <Input
              name="repeatPassword"
              type="password"
              id="repeatPassword"
              value={values.repeatPassword}
              onChange={handleChange}
              required
              isError={
                errors.repeatPassword !== undefined && touched.repeatPassword
              }
              errorText={errors.repeatPassword}
            />
          </div>
        </div>
        <Button fullWidth size="xl" isLoading={loading} type="submit">
          Continue
        </Button>
      </form>
    </>
  );
}
