'use client';

import { useNewsletterSubscription } from '@hooks/use-newsletter-subscription';
import { Button, Input } from '@v2/ui';

export type Tag = 'Newsletter' | 'Concerts';

type Props = {
  tag?: Tag;
};

export function NewsLetterCta({ tag = 'Newsletter' }: Props) {
  const { email, setEmail, isLoading, handleSubmit } =
    useNewsletterSubscription(tag);

  return (
    <div className="mx-auto bg-gray-900 p-10 px-4 py-10 text-center md:px-0 md:py-20">
      <h2 className="mb-4 text-2xl font-bold text-white md:mb-5 md:text-4xl">
        Sign up for our newsletter
      </h2>
      <p className="text-md font-medium text-gray-400 md:text-lg">
        Never miss out on hearing about our new trips when they release!
      </p>
      <form
        className="mx-auto mt-8 flex items-stretch justify-center lg:mt-10 lg:items-center"
        onSubmit={handleSubmit}
      >
        <div className="w-auto lg:min-w-80">
          <Input
            extent="md"
            name="email"
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="h-[44px] rounded-r-none border-r-0"
            placeholder="Enter your email address"
          />
        </div>
        <Button
          type="submit"
          className="rounded-l-none border-l-0"
          isLoading={isLoading}
        >
          Sign up
        </Button>
      </form>
    </div>
  );
}
