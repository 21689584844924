import { addQueryParam } from '@utils/add-query-param';

const tevoSiteUri = process.env.TICKET_EVO_BASE_URL;
const concatUri = (link: string) => tevoSiteUri + link;

export default {
  categories: concatUri('/categories'),
  category: (id: string) => `${concatUri('/categories')}/${id}`,
  eventsSearch: (queryObj: Object) =>
    `${concatUri('/events/search')}?${addQueryParam(queryObj)}`,
  events: (queryObj: Object) =>
    `${concatUri('/events')}?${addQueryParam(queryObj)}`,
  event: (id: number | string) => `${concatUri('/events')}/${id}`,
  performers: (name: Object) => concatUri(`/performers/${name}`),
  venues: (name: Object) => concatUri(`/venues/${name}`),
  performersSearch: (queryObj: Object) =>
    `${concatUri('/performers/search')}?${addQueryParam(queryObj)}`,
  concat: (str: string) => `${concatUri(str)}`,
  search: (queryObj: Object) =>
    `${concatUri('/search')}?${addQueryParam(queryObj)}`,
  configuration: (id: number) => `${concatUri('/configurations')}/${id}`,
  getEventProxy: (eventId: string | number, needTickets?: boolean) =>
    `/te/event?${addQueryParam({ eventId, needTickets })}`,
  listings: (
    event_id: string | number,
    lightweight?: false,
    type: string = 'event'
  ) =>
    concatUri(
      `/listings?${addQueryParam({
        event_id,
        lightweight,
        type,
      })}`
    ),
  createOrder: concatUri('/orders'),
  taxQuotes: concatUri('/tax_quotes'),
};
