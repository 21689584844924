import { useState } from 'react';
import toast from 'react-hot-toast';
import routes from '@config/routes';
import instance from '@instance';
import { AlertToast, AlertTriangle, CheckCircle } from '@v2/ui';

type Tag = 'Newsletter' | 'Concerts';

export function useNewsletterSubscription(tag: Tag = 'Newsletter') {
  const [email, setEmail] = useState('');
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      setLoading(true);

      const response = await instance.server(routes.addTagToUser, {
        body: JSON.stringify({
          email: email.toLowerCase(),
          tag,
        }),
      });

      if (response.ok) {
        toast.custom(
          (t) => (
            <AlertToast
              {...t}
              title="Success"
              content={'Email subscribed'}
              icon={<CheckCircle />}
            />
          ),
          { position: 'top-right' }
        );

        setEmail('');
      } else {
        const { message } = await response.json();

        toast.custom(
          (t) => (
            <AlertToast
              {...t}
              title="Oops, something went wrong"
              content={message || 'Please try again in a minute.'}
              icon={<AlertTriangle />}
            />
          ),
          { position: 'top-right' }
        );
      }
    } catch (e: any) {
      toast.custom(
        (t) => (
          <AlertToast
            {...t}
            title="Oops, something went wrong"
            content={'Please try again in a minute.'}
            icon={<AlertTriangle />}
          />
        ),
        { position: 'top-right' }
      );
    } finally {
      setLoading(false);
    }
  };

  return {
    email,
    setEmail,
    isLoading,
    handleSubmit,
  };
}
