'use client';

import {
  useDashboardLinks,
  type DashboardRoute,
} from '@hooks/use-dashboard-links';
import { useBetterMediaQuery } from '@hooks/use-ssr-media-query';
import type { LoggedInUserProps } from '@interfaces/auth';
import { UserAgencyResponse } from '@v2/types';

import { Divider, Skeleton, Typography } from '@v2/ui';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

const iconSize = '20';

type Props = {
  user: LoggedInUserProps | null;
  agencyDetails: UserAgencyResponse | null;
};

const SKELETON_ARRAY = [1, 2, 3];
export function Sidebar(props: Props) {
  const { user, agencyDetails } = props;

  const allowedSections = useDashboardLinks(user);
  const matches = useBetterMediaQuery('(max-width: 1024px)');

  if (!allowedSections.length)
    return (
      <div className="flex min-w-[200px] flex-col gap-y-8 xl:min-w-[264px]">
        <div>
          <Skeleton className="mb-1 h-4 w-[250px]" />
          <Skeleton className="h-4 w-[160px]" />
        </div>

        <div>
          {SKELETON_ARRAY.map((item, i) => (
            <div key={item}>
              <Skeleton className="mb-1 h-4 w-[150px]" />

              {[0, 1, 2, 3, 4].map((x) => (
                <Skeleton
                  className="my-2 h-4 w-[100px]"
                  key={`${x}-subchild`}
                />
              ))}

              {i < SKELETON_ARRAY.length - 1 && <Divider className="my-4" />}
            </div>
          ))}
        </div>
      </div>
    );

  if (matches) return null;

  return (
    <div className="flex min-w-[200px] flex-col gap-y-8 xl:min-w-[264px]">
      <div>
        <Typography
          variant="h3"
          size="txl"
          className="mb-2 w-[17ch] truncate font-bold text-gray-800 xl:w-[24ch]"
        >
          {user?.firstName}&nbsp;{user?.lastName}
        </Typography>
        <Typography
          variant="h3"
          size="tmd"
          className="w-[17ch] truncate xl:w-[24ch]"
        >
          {user?.email}
        </Typography>
        {agencyDetails?.businessName && (
          <Typography
            variant="h3"
            size="tsm"
            className="w-[17ch] truncate text-gray-500 xl:w-[24ch]"
          >
            {agencyDetails?.businessName}
          </Typography>
        )}
      </div>
      <div>
        {allowedSections.map((link, i) => {
          const { items, title } = link;

          return (
            <div key={title}>
              {title && (
                <h3 className="text-md mb-1 font-medium text-gray-500">
                  {title}
                </h3>
              )}
              <LinksSection list={items} />

              {i < allowedSections.length - 1 && <Divider className="my-4" />}
            </div>
          );
        })}
      </div>
    </div>
  );
}

function LinksSection({ list }: { list: DashboardRoute[] }) {
  const pathname = usePathname();

  const isSelectedByChildren = (childs) => {
    if (!pathname) return false;
    return childs.find((x) =>
      x.match(/:id$/)
        ? x === pathname.replace(/\/[^/]+$/, '/:id')
        : x === pathname
    );
  };

  return (
    <div>
      {list.map((item) => {
        const { icon, title, path, childs } = item;
        const Icon = icon;
        const isActive =
          pathname?.startsWith(path) ||
          (childs && isSelectedByChildren(childs));
        const iconStrokeColor = isActive ? '#FA2846' : '#667085';

        return (
          <Link
            href={path}
            key={path}
            className="flex items-center gap-x-2.5 py-2"
            prefetch={false}
          >
            <Icon
              size={iconSize}
              // viewBox={iconViewBox}
              pathProps={{ stroke: iconStrokeColor }}
            />
            <Typography
              variant="h3"
              size="tmd"
              className="font-semibold text-gray-800"
            >
              {title}
            </Typography>
          </Link>
        );
      })}
    </div>
  );
}
