'use client';

import { useAuthUserType } from '@hooks/use-auth-user-type';
import { v2Links } from '@utils/navigation/links';
import { sendVerificationCode } from '@v2/action';
import { AlertToast, AlertTriangle, Button, Google, Input } from '@v2/ui';
import { HorizontalDividerWithText } from '@v2/ui/Divider/HorizontalDividerWithText';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useTransition } from 'react';
import toast from 'react-hot-toast';
import { useSignin } from '../use-auth';

export const AUTH_PROVIDERS_URLS = {
  google: {
    path: `${process.env.SERVER_URL}/auth/google`,
    text: 'with Google',
  },
  facebook: {
    path: `${process.env.SERVER_URL}/auth/facebook`,
  },
};

export function SigninFlow() {
  const router = useRouter();
  const userType = useAuthUserType();

  const [isPending, startTransition] = useTransition();

  const [{ formik, loading }] = useSignin({
    onSubmitCallback: async (data) => {
      const emailParam = encodeURIComponent(formik.values.email);
      const email = formik.values.email;
      const values = {
        ...formik.values,
        email,
      };

      startTransition(async () => {
        if (
          data == 'User not found.' ||
          (typeof data === 'object' && data.status === 'partial')
        ) {
          userType === 'agency'
            ? router.push(`${v2Links.partnerSignup}`)
            : router.push(
                `${v2Links.completeAccount}?email=${emailParam}&userType=${userType}`
              );
          return;
        }
        const res = await sendVerificationCode({ ...values, userType });

        if (typeof res === 'string' && res !== 'Success') {
          toast.custom(
            (t) => (
              <AlertToast
                {...t}
                title="Oops, something went wrong"
                content={res}
                icon={<AlertTriangle />}
              />
            ),
            { position: 'top-right' }
          );
          return;
        }

        if (res?.error) {
          toast.custom(
            (t) => (
              <AlertToast
                {...t}
                title="Oops, something went wrong"
                content={res.message}
                icon={<AlertTriangle />}
              />
            ),
            { position: 'top-right' }
          );
          return;
        }

        router.push(
          `${v2Links.confirmIdentity}?email=${emailParam}&userType=${userType}`
        );
      });
    },
  });
  return (
    <>
      {userType === 'user' && (
        <>
          <div>
            <Link href={AUTH_PROVIDERS_URLS['google'].path}>
              <Button
                size="xl"
                variant="secondary"
                className="flex w-full items-center justify-center"
              >
                <div className="flex items-center">
                  <Google className="mr-[10px]" size={'20'} />
                  Sign in with Google
                </div>
              </Button>
            </Link>
            {/* <Link href={AUTH_PROVIDERS_URLS['facebook'].path}>
          <Button
            size="xl"
            variant="secondary"
            className="w-full flex items-center justify-center mt-3"
          >
            <div className="flex items-center ">
              <Facebook className="mr-[10px]" size={'20'} />
              Sign in with Facebook
            </div>
          </Button>
        </Link> */}
          </div>
          <HorizontalDividerWithText>OR</HorizontalDividerWithText>
        </>
      )}

      <div>
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-y-3">
          <Input
            extent="md"
            required
            type="email"
            placeholder="Enter your email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            isError={formik.touched.email && Boolean(formik.errors.email)}
            errorText={formik.errors.email}
          />

          <Button
            name="signinButton"
            type="submit"
            variant="primary"
            size="xl"
            className="w-full"
            isLoading={loading || isPending}
          >
            Continue
          </Button>
        </form>
      </div>
    </>
  );
}
